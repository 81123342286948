import Avatar from "@/components/avatar"
import { ContractSigneeListView } from "@/modules/contracts/models/contractSigneeListView"
import { parseName } from "@/utils/helpers"
import dayjs from "dayjs"
import { SigneeStatus } from "./SigneeStatus"
import { useTranslation } from "react-i18next"

export const Signee = ({ signee }: { signee: ContractSigneeListView }) => {
  const { t } = useTranslation(["common", "contracts"])

  return (
    <div key={signee.uid} className="flex items-start gap-1">
      <div className="relative">
        <Avatar className="self-start" email={signee.email} size={32} />
        <SigneeStatus
          className="absolute -bottom-1 -right-0.5 z-10"
          signee={signee}
        />
      </div>
      <div className="mt-0.5">
        <h5 className="text-sm font-medium leading-none">
          {parseName(signee)}
        </h5>
        <p className="text-[12px] text-gray-400">
          {signee.shouldSign === true
            ? signee.signature.isSigned
              ? `${t("contracts:signed_on")} ${dayjs(signee.signature.signingDate).format("DD MMMM YYYY")}`
              : t("common:pending")
            : t("contracts:not_required")}
        </p>
      </div>
    </div>
  )
}
