import { Icon, TIconName } from "@/core/components/icon/Icon"
import { cn } from "@/utils/helpers"
import { useTranslation } from "react-i18next"
import { ContractStatus } from "../../constants/contractStatus"

type CellStatusProps = {
  status: ContractStatus
}

export const CellStatus = ({ status }: CellStatusProps) => {
  const { t } = useTranslation("common")
  let icon: TIconName = "schedule"
  let iconColor: string = "text-orange-500"
  let translationKey: string = status.toLocaleLowerCase()

  if (status === ContractStatus.Active) {
    icon = "check_circle"
    iconColor = "text-green-500"
  }
  if (status === ContractStatus.Rejected) {
    icon = "cancel"
    iconColor = "text-red-400"
  }
  if (status === ContractStatus.Ended) {
    icon = "hourglass_bottom"
    iconColor = "text-red-400"
  }
  if (status === ContractStatus.NewRequest) {
    icon = "error"
    iconColor = "text-gray-500"
    translationKey = "new_request"
  }

  return (
    <div
      className={cn(
        "flex w-fit items-center justify-center gap-1 rounded-xl px-2 py-0.5",
        status === ContractStatus.Active && "bg-green-100",
        status === ContractStatus.Pending && "bg-orange-100",
        status === ContractStatus.Rejected && "bg-red-100",
        status === ContractStatus.NewRequest && "bg-gray-100",
        status === ContractStatus.Ended && "bg-red-100",
      )}
    >
      <Icon icon={icon} className={cn("text-[16px]", iconColor)} />
      <p className="text-sm text-gray-600">{t(translationKey)}</p>
    </div>
  )
}
