import { FetchClient } from "@/core/client/fetch_client"
import { StatusCode } from "@/core/generated/api/enums/status_code"
import { PagingModel } from "@/core/generated/api/models/paging_model"
import { ResponseModel } from "@/core/generated/api/models/response_model"
import { ResponseSerializer } from "@/core/generated/api/response_serializer"
import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { getService } from "hooks/useStartup"
import { useState } from "react"
import { GetContragsArgs } from "../../models/args/getContractsArgs"
import { ContractListView } from "../../models/contractListView"
import { contractQueryKeys } from "../../factories/contract-queries"

export const useContracts = (
  args: GetContragsArgs,
  options?: Omit<
    UseQueryOptions<PagingModel<ContractListView>>,
    "queryKey" | "queryFn"
  >,
) => {
  const [code, setCode] = useState<number>(StatusCode.Ok)
  const queryKey = contractQueryKeys.list(args)
  const client: FetchClient = getService(FetchClient)

  const { data, ...rest } = useQuery<PagingModel<ContractListView>>({
    ...(options ?? {}),
    queryKey,
    queryFn: async () => {
      const response: ResponseModel = await client.getWithParamsAsync(
        "/v1/contracts",
        args,
      )
      if (response !== null) {
        const model: PagingModel<ContractListView> | null =
          ResponseSerializer.deserialize<PagingModel<ContractListView>>(
            response,
          )
        if (model !== null) {
          return model
        } else {
          setCode(StatusCode.NoContent)
        }
      } else {
        setCode(StatusCode.BadRequest)
      }
    },
  })

  return { data, code, ...rest }
}
