import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/core/components/Popover"
import { SigneeType } from "@/modules/contracts/constants/signeeType"
import { ContractPartyListView } from "@/modules/contracts/models/contractPartyListView"
import { Separator } from "@/shared/components/ui/separator"
import { parseName } from "@/utils/helpers"
import { Signee } from "../Signee"
import { SigneeStatus } from "../SigneeStatus"
import { useTranslation } from "react-i18next"

type CellCandidatesProps = {
  parties: ContractPartyListView[]
}

export const CellCandidates = ({ parties }: CellCandidatesProps) => {
  const { t } = useTranslation("common")
  const partiesWithCandidates = parties.filter((party) =>
    party.signees.some((signee) => signee.type === SigneeType.Talent),
  )
  const reducedCandidates = partiesWithCandidates.reduce((acc, cur) => {
    const candidates = []
    cur.signees.map((signee) => {
      if (signee.type === SigneeType.Talent) {
        candidates.push({
          ...signee,
          space: {
            uid: cur.uid,
            name: cur.name,
            reference: cur.reference,
          },
        })
      }
    })
    return [...acc, ...candidates]
  }, [])

  return (
    <div className="flex flex-wrap items-center gap-2">
      {reducedCandidates.map((candidate) => (
        <Popover key={candidate.uid}>
          <PopoverTrigger className="bg-neutral-container/50 data-[state=open]:bg-neutral-container data-[state=open]:border-outline text-on-neutral-container hover:bg-neutral-container transition-color border-neutral-container flex items-center gap-1 rounded-lg border px-2 py-0.5 duration-300">
            <SigneeStatus signee={candidate} />
            {parseName(candidate)}
          </PopoverTrigger>
          <PopoverContent className="space-y-2 bg-white">
            <Signee key={candidate.uid} signee={candidate} />
            <Separator />
            <div>
              <h5 className="text-sm font-medium">{candidate.space.name}</h5>
              <p className="text-[12px] text-gray-400">
                {`${t("vat")}: ${candidate.space.reference}`}
              </p>
            </div>
          </PopoverContent>
        </Popover>
      ))}
    </div>
  )
}
