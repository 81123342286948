import { cn } from "@/utils/helpers"
import { iconList } from "./IconUtil"
import { forwardRef } from "react"

export type TIconName = (typeof iconList)[number]

export type TIconProps = {
  icon: TIconName
  fill?: boolean
  weight?: number
  gradient?: number
  opticalSize?: number
  className?: string
  size?: "sm" | "md" | "lg"
}

/**
 * Icon component to render Material Symbols icons with customizable styles.
 *
 * @param {TIconProps} props - The props for the Icon component.
 * @param {string} props.icon - The name of the icon to render.
 * @param {boolean} [props.fill=false] - Whether the icon should be filled. Defaults to false.
 * @param {number} [props.weight=400] - The weight of the icon. Defaults to 400.
 * @param {number} [props.gradient=0] - The gradient of the icon. Defaults to 0.
 * @param {number} [props.opticalSize=24] - The optical size of the icon. Defaults to 24.
 * @param {string} [props.className] - Additional CSS classes to apply to the icon.
 * @param {"sm" | "md" | "lg"} [props.size="md"] - The size of the icon. Defaults to "md".
 * @returns {JSX.Element} The rendered icon.
 */
const Icon = forwardRef<HTMLSpanElement, TIconProps>((props, ref) => {
  return (
    <span
      ref={ref}
      style={{
        fontVariationSettings: `'FILL' ${props.fill ? 1 : 0}, 'wght' ${props.weight ?? 300}, 'GRAD' ${props.gradient ?? 0}, 'opsz' ${props.opticalSize ?? 24}`,
      }}
      className={cn(
        "material-symbols-outlined text-5xl",
        props.size === "sm" && "text-2xl",
        props.size === "md" && "text-4xl",
        props.size === "lg" && "text-6xl",
        props.className,
      )}
    >
      {props.icon}
    </span>
  )
})

Icon.displayName = "Icon"

export { Icon }
