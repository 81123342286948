import "reflect-metadata"
import { TaskProvider } from "@/modules/tasks/providers/taskProvider"
import { TaskRepository } from "@/modules/tasks/repositories/taskRepository"
import { CacheProvider } from "@/shared/cache/cacheProvider"
import { container, InjectionToken } from "tsyringe"
import { TaskTemplateListView } from "@/modules/tasks/models/taskTemplateListView"
import { registerClass } from "@/core/generated/api/decorators/serializable_decorator"
import { FetchClient } from "@/core/client/fetch_client"
import { FetchSettings } from "@/core/client/fetch_settings"
import { TaskSidebarModel } from "@/modules/tasks/models/taskSideBarModel"
import { OpportunityListView } from "@/modules/tasks/supplier/models/opportunityListView"
import { PagingModel } from "@/core/generated/api/models/paging_model"
import { ContractDetails } from "@/modules/contracts/models/contractDetails"

export const useStartup = () => {
  container.register(FetchSettings, { useClass: FetchSettings })
  container.register(FetchClient, {
    useValue: new FetchClient(getService(FetchSettings)),
  })

  container.register(TaskRepository, {
    useValue: new TaskRepository(getService(FetchClient)),
  })
  container.register(TaskProvider, {
    useValue: new TaskProvider(
      getService(CacheProvider),
      getService(TaskRepository),
    ),
  })

  registerClass(PagingModel)
  registerClass(TaskSidebarModel)
  registerClass(TaskTemplateListView)
  registerClass(OpportunityListView)
  registerClass(ContractDetails)
}

export const getService = <T>(token: InjectionToken<T>): T => {
  return container.resolve(token)
}
