import { Label } from "@/core/components/Label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/core/components/Popover"
import { ContractListView } from "@/modules/contracts/models/contractListView"
import { Separator } from "@/shared/components/ui/separator"
import { Signee } from "../Signee"
import { useTranslation } from "react-i18next"
import { Icon } from "@/core/components/icon/Icon"

type CellPartiesProps = {
  parties: ContractListView["parties"]
}

export const CellParties = ({ parties }: CellPartiesProps) => {
  const { t } = useTranslation(["common", "contracts"])

  return (
    <div className="flex flex-wrap items-center gap-2">
      {parties.map((space) => (
        <Popover key={space.uid}>
          <PopoverTrigger className="bg-neutral-container/50 data-[state=open]:bg-neutral-container data-[state=open]:border-outline text-on-neutral-container hover:bg-neutral-container transition-color border-neutral-container flex items-center gap-1 rounded-lg border px-2 py-0.5 duration-300">
            <Icon icon="domain" className="text-base" />
            {space.name}
          </PopoverTrigger>
          <PopoverContent className="space-y-2 bg-white">
            <div>
              <h5 className="text-sm font-medium">{space.name}</h5>
              <p className="text-[12px] text-gray-400">
                {`${t("common:vat")}: ${space.reference}`}
              </p>
            </div>
            <Separator />
            <Label className="text-sm text-gray-500">
              {t("contracts:signees")}
            </Label>
            <div className="space-y-2">
              {space.signees.map((signee) => (
                <Signee key={signee.uid} signee={signee} />
              ))}
            </div>
          </PopoverContent>
        </Popover>
      ))}
    </div>
  )
}
