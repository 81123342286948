import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/utils/helpers"

const labelVariants = cva("block", {
  variants: {
    variant: {
      default: "",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
    },
    size: {
      default: "text-[0.875rem] leading-4",
      xs: "text-xs leading-3",
      sm: "text-sm leading-4",
      md: "text-md leading-5",
      lg: "text-[16px] leading-5",
      xl: "text-xl leading-6",
      icon: "h-9 w-9",
    },
    type: {
      default: "",
      hyperlink: "cursor-pointer underline",
      upperCase: "uppercase",
    },
    family: {
      default: "font-inter",
      roboto: "font-roboto-mono",
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      type: "default",
      family: "default",
    },
  },
})

export interface LabelProps
  extends React.HTMLAttributes<HTMLLabelElement>,
    VariantProps<typeof labelVariants> {
  asChild?: boolean
}

const Label = React.forwardRef<HTMLLabelElement, LabelProps>(
  (
    { className, variant, size, type, family, asChild = false, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "label"
    return (
      <Comp
        className={cn(
          labelVariants({ variant, size, type, family, className }),
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Label.displayName = "Label"

export { Label, labelVariants }
