import { Icon } from "@/core/components/icon/Icon"
import { Button } from "@/shared/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu"
import { useTranslation } from "next-i18next"

type TableContractOptionsProps = {
  isOwner: boolean
  isAdministrator: boolean
  isApproved: boolean | null
  onView: () => void
  onDownload: () => void
  onExtend: () => void
  onApprove: () => void
  onReject: () => void
  onSend: () => void
  onDelete: () => void
}

export const TableContractOptions = ({
  isOwner,
  isAdministrator,
  isApproved,
  onView,
  onDownload,
  onExtend,
  onApprove,
  onReject,
  onSend,
  onDelete,
}: TableContractOptionsProps) => {
  const { t } = useTranslation("common")
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <Icon icon="more_horiz" className="text-3xl" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={onView}>
          <Icon icon="visibility" className="mr-1 text-[18px]" />
          {t("view")}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onDownload}>
          <Icon icon="download" className="mr-1 text-[18px]" />
          {t("download")}
        </DropdownMenuItem>
        {isOwner && isAdministrator && isApproved === null && (
          <>
            <DropdownMenuItem
              className="text-green-500 focus:text-green-500"
              onClick={onApprove}
            >
              <Icon icon="check" className="mr-1 text-[18px]" />
              {t("approve")}
            </DropdownMenuItem>
            <DropdownMenuItem
              className="text-red-400 focus:text-red-400"
              onClick={onReject}
            >
              <Icon icon="close" className="mr-1 text-[18px]" />
              {t("reject")}
            </DropdownMenuItem>
          </>
        )}
        {isApproved && (
          <>
            {isOwner && (
              <DropdownMenuItem onClick={onSend}>
                <Icon icon="send" className="mr-1 text-[18px]" />
                {t("send")}
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={onExtend}>
              <Icon icon="cached" className="mr-1 text-[18px]" />
              {t("extend")}
            </DropdownMenuItem>
          </>
        )}
        {isApproved !== null && isOwner && isAdministrator && (
          <DropdownMenuItem
            className="text-red-400 focus:text-red-400"
            onClick={onDelete}
          >
            <Icon icon="delete" className="mr-1 text-[18px]" />
            {t("delete")}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
