import { Spinner } from "@/core/components/Spinner"
import { ContractListView } from "@/modules/contracts/models/contractListView"
import { ContractPartyListView } from "@/modules/contracts/models/contractPartyListView"
import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useTranslation } from "next-i18next"
import { ContractStatus } from "../../constants/contractStatus"
import { CellCandidates } from "../cells/CellCandidate"
import { CellParties } from "../cells/CellParties"
import { CellStatus } from "../cells/CellStatus"
import { TableContractOptions } from "./TableContractOptions"

interface ITableContracts {
  contracts: ContractListView[]
  loading: boolean
  currentContractId?: string | null
  showOptions?: boolean
  onView?: (contract: ContractListView) => void
  onDownload?: (contract: ContractListView) => void
  onSend?: (contract: ContractListView) => void
  onRenew?: (contract: ContractListView) => void
  onApprove?: (contract: ContractListView) => void
  onReject?: (contract: ContractListView) => void
  onDelete?: (contract: ContractListView) => void
}

export const TableContracts = ({
  contracts,
  loading,
  currentContractId,
  showOptions = true,
  onView,
  onDownload,
  onSend,
  onRenew,
  onApprove,
  onReject,
  onDelete,
}: ITableContracts) => {
  const { t } = useTranslation(["common", "contracts"])

  const getContractStatus = (contract: ContractListView): ContractStatus => {
    const endDate = contract?.endDate
    const isActive = contract.parties.every((p) =>
      p.signees.every(
        (s) => !s.shouldSign || (s.shouldSign && s.signature.isSigned),
      ),
    )

    const isPastToday = (date) => dayjs().isAfter(dayjs(new Date(date)))
    if (isPastToday(endDate)) {
      return ContractStatus.Ended
    }
    if (contract?.isApproved === false) {
      return ContractStatus.Rejected
    }
    if (contract?.isApproved === null) {
      return ContractStatus.NewRequest
    }
    if (!isActive) {
      return ContractStatus.Pending
    }
    return ContractStatus.Active
  }

  const columns: ColumnsType<ContractListView> = [
    {
      title: t("contracts:table.title"),
      dataIndex: "title",
      key: "title",
      render: (title: string) => {
        return title ? title : "No title"
      },
      fixed: "left",
      minWidth: 200,
    },
    {
      title: t("contracts:table.document_type"),
      dataIndex: "templateName",
      key: "name",
      render: (templateName: string) => {
        return <div className="flex">{templateName}</div>
      },
    },
    {
      title: t("contracts:table.parties.title"),
      dataIndex: "parties",
      key: "parties",
      width: 512,
      render: (parties: ContractPartyListView[]) => {
        return <CellParties parties={parties} />
      },
    },
    {
      title: t("contracts:table.candidates"),
      dataIndex: "parties",
      key: "consultants",
      width: 256,
      render: (parties: ContractPartyListView[]) => {
        return <CellCandidates parties={parties} />
      },
    },
    {
      title: t("contracts:table.start_date"),
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate) => {
        if (startDate) {
          return dayjs(startDate).format("DD MMM YYYY")
        }
        return (
          <div className="text-xs italic">
            {t("contracts:table.no_start_date")}
          </div>
        )
      },
    },
    {
      title: t("contracts:table.end_date"),
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate) => {
        if (endDate) {
          return dayjs(endDate).format("DD MMM YYYY")
        }
        return (
          <div className="text-xs italic">
            {t("contracts:table.no_end_date")}
          </div>
        )
      },
    },
    {
      title: t("contracts:table.ends_in"),
      key: "endsin",
      render: (_, record) => {
        const startDate = record?.startDate
        const endDate = record?.endDate
        if (!endDate && !startDate)
          return (
            <div className="text-xs italic">
              {t("contracts:table.no_dates_defined")}
            </div>
          )

        if (!endDate && !startDate)
          return (
            <div className="text-xs italic">
              {t("contracts:table.no_dates_defined")}
            </div>
          )

        if (!dayjs(startDate).isBefore(dayjs()))
          return (
            <div className="text-xs italic">
              {t("contracts:table.contract_starts_in", {
                days: dayjs(startDate).diff(dayjs(), "days"),
              })}
            </div>
          )

        if (endDate) {
          const daysUntilEnd = dayjs(endDate).diff(dayjs(), "days")
          if (daysUntilEnd < 0)
            return (
              <div className="text-xs italic">
                {t("contracts:table.contract_ended_in", {
                  days: Math.abs(daysUntilEnd),
                })}
              </div>
            )
          return daysUntilEnd > 0 ? (
            <div className="text-xs italic">
              {t("contracts:table.days", { days: daysUntilEnd })}
            </div>
          ) : (
            <div className="text-xs italic">{t("common:today")}</div>
          )
        }
        return (
          <div className="text-xs italic">
            {t("contracts:table.no_end_date")}
          </div>
        )
      },
    },
    {
      title: t("contracts:table.status"),
      key: "status",
      fixed: "right",
      render: (_, record) => {
        const status = getContractStatus(record)
        return <CellStatus status={status} />
      },
    },
    showOptions
      ? {
          title: t("common:options"),
          key: "options",
          fixed: "right",
          className: " w-16",
          render: (_, record) => {
            return (
              <TableContractOptions
                isOwner={record.isOwner}
                isAdministrator={record.isAdministrator}
                isApproved={record.isApproved}
                onView={() => onView?.(record)}
                onDownload={() => onDownload?.(record)}
                onApprove={() => onApprove?.(record)}
                onReject={() => onReject?.(record)}
                onExtend={() => onRenew?.(record)}
                onSend={() => onSend?.(record)}
                onDelete={() => onDelete?.(record)}
              />
            )
          },
        }
      : {},
  ]

  return (
    <Table
      dataSource={contracts}
      columns={columns}
      rowKey={"uid"}
      scroll={{ x: "max-content" }}
      loading={{
        spinning: loading,
        indicator: (
          <div>
            <Spinner className="text-5xl text-blue-500" />
          </div>
        ),
      }}
      rowClassName={(record) =>
        record.uid === currentContractId ? "text-blue-500" : ""
      }
      size="small"
      pagination={false}
    />
  )
}
