import { cn } from "@/utils/helpers"
import { forwardRef } from "react"
import { Icon, TIconProps } from "./icon/Icon"

const Spinner = forwardRef<HTMLSpanElement, Omit<TIconProps, "icon">>(
  ({ className, ...props }, ref) => {
    return (
      <Icon
        {...props}
        ref={ref}
        icon="progress_activity"
        className={cn("animate-spin text-base text-white", className)}
      />
    )
  },
)

Spinner.displayName = "Spinner"

export { Spinner }
